<template>
  <div class="account-suspend-warning text-sm" v-if="$store.state.showHeader && visible">
    Your account is at risk of suspension {{ expiryInfo }} due to non-payment. Please contact Growlytics support if you have any billing queries.
    <div class="float-right mr-3 text-base cursor-pointer">
      <i class="fa-regular fa-circle-xmark" @click="visible = false"></i>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    message: { required: false, default: `Something's not right!` },
    description: { required: false, default: 'Please try again in some time!' }
  },
  data() {
    return {
      visible: false,
      expiryInfo: ''
    };
  },
  methods: {
    showDialog() {
      // Check visiblity first.
      this.visible = false;
      if (this.$store.state?.token?.companySuspendWarning) {
        this.visible = true;
      }

      let expiry = moment().endOf('month').add(1, 'day');
      let dayOfMonth = moment().date();

      if (dayOfMonth > 22) {
        let expiryTime = expiry.format('Do MMM YYYY');
        let expiryDays = expiry.diff(moment(), 'days');
        this.expiryInfo = `in ${expiryDays} days (${expiryTime})`;
      } else {
        this.expiryInfo = ``;
      }
    }
  },

  mounted() {
    this.showDialog();
    this.$root.$on('newSession', () => {
      this.showDialog();
    });
  }
};
</script>

<style lang="scss">
.account-suspend-warning {
  position: absolute;
  top: 0px;
  z-index: 9999999999;
  background: red;
  width: 100%;
  padding: 7px 0px 5px 0px;
  color: white;
  text-align: center;
}
</style>
