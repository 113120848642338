import Vue from 'vue';
import Router from 'vue-router';

// Charts
const ChartList = (resolve) => {
  require.ensure(['./pages/chart/chart'], () => {
    resolve(require('./pages/chart/chart'));
  });
};

// Revenue
const Revenue = (resolve) => {
  require.ensure(['./pages/revenue/revenue'], () => {
    resolve(require('./pages/revenue/revenue'));
  });
};

// Event List
const EventList = (resolve) => {
  require.ensure(['./pages/event/list/eventList'], () => {
    resolve(require('./pages/event/list/eventList'));
  });
};

// Event Details
const EventDetails = (resolve) => {
  require.ensure(['./pages/event/details/eventDetail'], () => {
    resolve(require('./pages/event/details/eventDetail'));
  });
};

// Email Campaign List
const EmailCampaignList = (resolve) => {
  require.ensure(['./pages/campaignList/email/emailCampaignList'], () => {
    resolve(require('./pages/campaignList/email/emailCampaignList'));
  });
};

// Mobile Push Campaign List
const MobilePushCampaignList = (resolve) => {
  require.ensure(['./pages/campaignList/mobilePush/mobilePushCampaignList'], () => {
    resolve(require('./pages/campaignList/mobilePush/mobilePushCampaignList'));
  });
};

//  Sms Campaign List
const SmsCampaignList = (resolve) => {
  require.ensure(['./pages/campaignList/sms/smsCampaignList'], () => {
    resolve(require('./pages/campaignList/sms/smsCampaignList'));
  });
};

//  in App Campaign List
const InAppCampaignList = (resolve) => {
  require.ensure(['./pages/campaignList/inApp/inAppCampaignList'], () => {
    resolve(require('./pages/campaignList/inApp/inAppCampaignList'));
  });
};
// Web Push Campaign List
const WebPushCampaignList = (resolve) => {
  require.ensure(['./pages/campaignList/webPush/webPushCampaignList'], () => {
    resolve(require('./pages/campaignList/webPush/webPushCampaignList'));
  });
};

// whats App Campaign List
const WhatsAppCampaignList = (resolve) => {
  require.ensure(['./pages/campaignList/whatsApp/whatsAppCampaignList'], () => {
    resolve(require('./pages/campaignList/whatsApp/whatsAppCampaignList'));
  });
};

// whats App Campaign List
const CustomChannelCampaignList = (resolve) => {
  require.ensure(['./pages/campaignList/customChannel/customChannelCampaignList'], () => {
    resolve(require('./pages/campaignList/customChannel/customChannelCampaignList'));
  });
};

// List Web Social Proof Campaign
const WebSocialProofCampaignList = (resolve) => {
  require.ensure(['./pages/campaignList/webSocialProof/webSocialProofCampaignList'], () => {
    resolve(require('./pages/campaignList/webSocialProof/webSocialProofCampaignList'));
  });
};

// Campaign Overview
const CampaignOverview = (resolve) => {
  require.ensure(['./pages/campaignOverview/campaignOverview'], () => {
    resolve(require('./pages/campaignOverview/campaignOverview'));
  });
};

// Campaign Report
const CampaignReport = (resolve) => {
  require.ensure(['./pages/campaignReport/campaignReport'], () => {
    resolve(require('./pages/campaignReport/campaignReport'));
  });
};

// Create Web Message Campaign
const CreateWebMessageCampaign = (resolve) => {
  require.ensure(['./pages/createWebMessageCampaign/createWebMessageCampaign'], () => {
    resolve(require('./pages/createWebMessageCampaign/createWebMessageCampaign'));
  });
};

// Create Web Message Campaign
const WebMessageList = (resolve) => {
  require.ensure(['./pages/webMessageList/webMessageList'], () => {
    resolve(require('./pages/webMessageList/webMessageList'));
  });
};

// Web Message Report
const WebMessageReport = (resolve) => {
  require.ensure(['./pages/webMessageReport/webMessageReport'], () => {
    resolve(require('./pages/webMessageReport/webMessageReport'));
  });
};

// RFM
const RfmBuilder = (resolve) => {
  require.ensure(['./pages/rfm/rfm'], () => {
    resolve(require('./pages/rfm/rfm'));
  });
};

// Login
const LoginPage = (resolve) => {
  require.ensure(['./pages/auth/login/login'], () => {
    resolve(require('./pages/auth/login/login'));
  });
};

// Shopify Connect Login
const ShopifyConnectLoginPage = (resolve) => {
  require.ensure(['./pages/auth/shopifyConnectLogin/shopifyConnectLogin'], () => {
    resolve(require('./pages/auth/shopifyConnectLogin/shopifyConnectLogin'));
  });
};

// Register
const RegisterPage = (resolve) => {
  require.ensure(['./pages/auth/register/register'], () => {
    resolve(require('./pages/auth/register/register'));
  });
};

// Forget Password
const ForgetPassword = (resolve) => {
  require.ensure(['./pages/auth/forgetPass/forgetPass'], () => {
    resolve(require('./pages/auth/forgetPass/forgetPass'));
  });
};

// Customer List
const CustomerOverview = (resolve) => {
  require.ensure(['./pages/customerOverview/customerOverview'], () => {
    resolve(require('./pages/customerOverview/customerOverview'));
  });
};

// Customer List
const CustomerList = (resolve) => {
  require.ensure(['./pages/customerList/customerList'], () => {
    resolve(require('./pages/customerList/customerList'));
  });
};

// Customer Details
const CustomerDetails = (resolve) => {
  require.ensure(['./pages/customerDetails/customerDetails'], () => {
    resolve(require('./pages/customerDetails/customerDetails'));
  });
};

const CustomersUpload = (resolve) => {
  require.ensure(['./pages/customersUploadV2/customersUploadV2'], () => {
    resolve(require('./pages/customersUploadV2/customersUploadV2'));
  });
};

const CustomerUploadHistory = (resolve) => {
  require.ensure(['./pages/customerUploadHistory/customerUploadHistory'], () => {
    resolve(require('./pages/customerUploadHistory/customerUploadHistory'));
  });
};

// User Profile
const ProfileDetails = (resolve) => {
  require.ensure(['./pages/profile/profile'], () => {
    resolve(require('./pages/profile/profile'));
  });
};

// Organization Settings
const OrganizationSettings = (resolve) => {
  require.ensure(['./pages/organizationSettings/settings/organizationSettings.vue'], () => {
    resolve(require('./pages/organizationSettings/settings/organizationSettings.vue'));
  });
};

// Page Not Found
const PageNotFound = (resolve) => {
  require.ensure(['./pages/others/404/404'], () => {
    resolve(require('./pages/others/404/404'));
  });
};

// Page Not Found
const ConfirmEmail = (resolve) => {
  require.ensure(['./pages/confirmEmail/confirmEmail'], () => {
    resolve(require('./pages/confirmEmail/confirmEmail'));
  });
};

// Custom Dashboard Page
const CustomDashboard = (resolve) => {
  require.ensure(['./pages/dashboard/customDashboard/customDashboard'], () => {
    resolve(require('./pages/dashboard/customDashboard/customDashboard'));
  });
};

// Custom Dashboard Page
const HardCodedDashboard = (resolve) => {
  require.ensure(['./pages/dashboard/hardcodedDashboard/hardCodedDashboard'], () => {
    resolve(require('./pages/dashboard/hardcodedDashboard/hardCodedDashboard'));
  });
};

// Company Selection
const SelectCompanyPage = (resolve) => {
  require.ensure(['./pages/selectCompany/selectCompany'], () => {
    resolve(require('./pages/selectCompany/selectCompany'));
  });
};

/***************#region - Chart Pages **************************/

// Funnel
const Funnel = (resolve) => {
  require.ensure(['./pages/funnel/funnel'], () => {
    resolve(require('./pages/funnel/funnel'));
  });
};

// Retention
const Retention = (resolve) => {
  require.ensure(['./pages/retentionAnalysis/retentionAnalysis'], () => {
    resolve(require('./pages/retentionAnalysis/retentionAnalysis'));
  });
};

/***************#endregion - Chart Pages **************************/

// Shopify Integration
const shopifyIntegrationPage = (resolve) => {
  require.ensure(['./pages/integration/shopify/shopifyIntegration'], () => {
    resolve(require('./pages/integration/shopify/shopifyIntegration'));
  });
};

// Journey Create
const JourneyList = (resolve) => {
  require.ensure(['./pages/journeyList/journeyList'], () => {
    resolve(require('./pages/journeyList/journeyList'));
  });
};

// Journey Create
const JourneyCreate = (resolve) => {
  require.ensure(['./pages/journeyCreate/createJourney'], () => {
    resolve(require('./pages/journeyCreate/createJourney'));
  });
};

// Journey Create
const JourneyUpdate = (resolve) => {
  require.ensure(['./pages/journeyCreate/createJourney'], () => {
    resolve(require('./pages/journeyCreate/createJourney'));
  });
};

/*********************  End: Auth *********************/

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  routes: [
    // Create Scheduled Reports
    {
      path: '/scheduled-reports',
      name: 'scheduledReportList',
      component: Vue.component('scheduledReportList', () => import('./pages/scheduledReports/list/scheduledReportList.vue'))
    },
    // Create Scheduled Reports
    {
      path: '/scheduled-reports/create',
      name: 'createScheduledReport',
      component: Vue.component('createScheduledReport', () => import('./pages/scheduledReports/create/createScheduledReports.vue'))
    },

    // Update Scheduled Reports
    {
      path: '/scheduled-reports/:id',
      name: 'createScheduledReport',
      component: Vue.component('createScheduledReport', () => import('./pages/scheduledReports/create/createScheduledReports.vue'))
    },

    // Create Email Template
    {
      path: '/templates/create',
      name: 'createTemplate',
      component: Vue.component('createTemplate', () => import('./pages/emailTemplates/create/createEmailTemplate.vue'))
    },

    // Coupon List
    {
      path: '/coupon',
      name: 'couponList',
      component: Vue.component('couponList', () => import('./pages/couponList/couponList'))
    },

    // CreateCoupon
    {
      path: '/coupon/create',
      name: 'couponCreate',
      component: Vue.component('couponCreate', () => import('./pages/couponCreate/couponCreate'))
    },

    // Update Coupon
    {
      path: '/coupon/:id',
      name: 'couponUpdate',
      component: Vue.component('couponCreate', () => import('./pages/couponCreate/couponCreate'))
    },

    // Facebook google remarketing
    {
      path: '/remarketing/facebook',
      name: 'FacebookRemarketingList',
      component: Vue.component('FacebookRemarketing', () => import('./pages/segmentRemarketing/facebookRemarketingList'))
    },
    {
      path: '/remarketing/google',
      name: 'GoogleRemarketingList',
      component: Vue.component('GoogleRemarketing', () => import('./pages/segmentRemarketing/googleRemarketingList'))
    },

    // Approval
    {
      path: '/approval',
      name: 'approval',
      component: Vue.component('approval', () => import('./pages/approval/approval'))
    },

    // Account Suspended
    {
      path: '/suspended',
      name: 'suspended',
      component: Vue.component('suspended', () => import('./pages/accountSuspended/accountSuspended'))
    },

    // Invitation
    {
      path: '/invitation',
      name: 'invitation',
      component: Vue.component('invitation', () => import('./pages/auth/invitation/invitation'))
    },

    // Onboarding
    {
      path: '/setup',
      name: 'onboarding',
      component: Vue.component('onboarding', () => import('./pages/onboarding/onboarding'))
    },

    // Create Campaign V1 - General Info Update
    {
      path: '/campaign/update/general/:id',
      name: 'campaignGeneralInfoUpdate',
      component: Vue.component('campaignGeneralInfoUpdate', () => import('./pages/campaignGeneralInfoUpdate/campaignGeneralInfoUpdate'))
    },

    // Create Campaign V1 - Target Audiance
    {
      path: '/campaign/update/audiance/:id',
      name: 'campaignTargetAudiance',
      component: Vue.component('campaignTargetAudiance', () => import('./pages/campaignTargetAudiance/campaignTargetAudiance'))
    },

    // Create Campaign V1 - Campaign Goals
    {
      path: '/campaign/update/goals/:id',
      name: 'campaignGoal',
      component: Vue.component('campaignGoal', () => import('./pages/campaignGoal/campaignGoal'))
    },

    // Create Campaign V1 - Email Content Page
    {
      path: '/campaign/update/email/:id',
      name: 'updateEmailContent',
      component: Vue.component('updateEmailContent', () => import('./pages/campaignContentPages/updateEmailContent'))
    },

    // Create Campaign V1 - Custom Channel Content Page
    {
      path: '/campaign/update/custom/:id',
      name: 'updateCustomChannelContent',
      component: Vue.component('updateCustomChannelContent', () => import('./pages/campaignContentPages/updateCustomChannelContent'))
    },

    // Create Campaign V1 - Sms Content Page
    {
      path: '/campaign/update/sms/:id',
      name: 'updateSmsContent',
      component: Vue.component('updateSmsContent', () => import('./pages/campaignContentPages/updateSmsContent'))
    },

    // Create Campaign V1 - WhatsApp Content Page
    {
      path: '/campaign/update/whatsApp/:id',
      name: 'updateWhatsAppContent',
      component: Vue.component('updateWhatsAppContent', () => import('./pages/campaignContentPages/updateWhatsAppContent'))
    },

    // Create Campaign V1 - WebPush Content Page
    {
      path: '/campaign/update/webPush/:id',
      name: 'updateWebPushContent',
      component: Vue.component('updateWebPushContent', () => import('./pages/campaignContentPages/updateWebPushContent'))
    },

    // Create Campaign V1 - Mobile Content Page
    {
      path: '/campaign/update/mobilePush/:id',
      name: 'updateMobilePushContent',
      component: Vue.component('updateMobilePushContent', () => import('./pages/campaignContentPages/updateMobilePushContent'))
    },

    // Create Campaign V1 - Social Proof Content Page
    {
      path: '/campaign/update/webSocialProof/:id',
      name: 'updateWebSocialProofContent',
      component: Vue.component('updateWebSocialProofContent', () => import('./pages/campaignContentPages/updateWebSocialProofContent'))
    },

    // Create Campaign V1 - In-App
    {
      path: '/campaign/update/inApp/:id',
      name: 'updateInAppContent',
      component: Vue.component('updateInAppContent', () => import('./pages/campaignContentPages/updateInAppContent'))
    },

    // Create Campaign V1 - Review & Launch
    {
      path: '/campaign/update/review/:id',
      name: 'campaignReviewLaunch',
      component: Vue.component('campaignReviewLaunch', () => import('./pages/campaignReviewLaunch/campaignReviewLaunch'))
    },

    // Campaign Overview
    {
      path: '/campaign/overview',
      name: 'CampaignOverview',
      component: CampaignOverview
    },

    // CampaignReport
    {
      path: '/campaign/report/:id',
      name: 'CampaignReport',
      component: CampaignReport
    },

    // Create Web Message
    {
      path: '/webMessage/:messageId/edit',
      name: 'CreateWebMessageCampaign',
      component: CreateWebMessageCampaign
    },

    // Web Message Report
    {
      path: '/webMessage/:messageId/report',
      name: 'WebMessageReport',
      component: WebMessageReport
    },

    // Web Message List
    {
      path: '/webMessage/:messageType',
      name: 'WebMessageList',
      component: WebMessageList
    },

    // Email Campaign List
    {
      path: '/campaigns/email',
      name: 'EmailCampaignList',
      component: EmailCampaignList
    },
    // Mobile Campaign List
    {
      path: '/campaigns/mobilePush',
      name: 'MobilePushCampaignList',
      component: MobilePushCampaignList
    },
    // Sms Campaign List
    {
      path: '/campaigns/sms',
      name: 'SmsCampaignList',
      component: SmsCampaignList
    },

    // inApp Campaign List
    {
      path: '/campaigns/inApp',
      name: 'InAppCampaignList',
      component: InAppCampaignList
    },

    // web social proof Campaign List
    {
      path: '/campaigns/webSocialProof',
      name: 'WebSocialProofCampaignList',
      component: WebSocialProofCampaignList
    },

    // WebPush Campaign List
    {
      path: '/campaigns/webPush',
      name: 'WebPushCampaignList',
      component: WebPushCampaignList
    },

    // WhatsApp Campaign List
    {
      path: '/campaigns/whatsApp',
      name: 'WhatsAppCampaignList',
      component: WhatsAppCampaignList
    },

    // Custom Channel Campaign List
    {
      path: '/campaigns/custom',
      name: 'CustomChannelCampaignList',
      component: CustomChannelCampaignList
    },

    // Charts
    {
      path: '/analytics/chart',
      name: 'ChartList',
      component: ChartList
    },

    // Revenue
    {
      path: '/analytics/revenue',
      name: 'Revenue',
      component: Revenue
    },

    // Events
    {
      path: '/analytics/events',
      name: 'EventList',
      component: EventList
    },

    {
      path: '/analytics/events/:eventName',
      name: 'EventDetails',
      component: EventDetails
    },

    //Auth routes
    {
      path: '/login',
      name: 'Login',
      component: LoginPage,
      meta: {
        title: 'Login'
      }
    },
    {
      path: '/shopify-connect',
      name: 'ShopifyConnectLogin',
      component: ShopifyConnectLoginPage,
      meta: {
        title: 'Connect Shopify Store'
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: RegisterPage,
      meta: {
        title: 'Register'
      }
    },
    {
      path: '/forgotPass',
      name: 'ForgetPassword',
      component: ForgetPassword,
      meta: {
        title: 'Forgot Password'
      }
    },

    {
      path: '/integrations/shopify',
      name: 'ShopifyIntegration',
      component: shopifyIntegrationPage
    },

    // select company
    {
      path: '/company/select',
      name: 'SelectCompany',
      component: SelectCompanyPage,
      meta: {
        title: 'Select Company'
      }
    },

    // User routes
    {
      path: '/user/detail',
      name: 'Profile',
      component: ProfileDetails,
      meta: {
        title: 'My Profile'
      }
    },
    {
      path: '/confirmEmail',
      name: 'ConfirmEmail',
      component: ConfirmEmail,
      meta: {
        title: 'Confirm Email'
      }
    },

    {
      path: '/',
      name: 'Dashboard',
      component: HardCodedDashboard
    },

    {
      path: '/dashboard/:dashboardId',
      name: 'Custom Dashboard',
      component: CustomDashboard
    },

    // Rfm Builder
    {
      path: '/segment/rfm',
      name: 'RFM',
      component: RfmBuilder,
      meta: {
        title: 'RFM'
      }
    },

    //Segment Routes
    {
      path: '/segment/query',
      name: 'SegmentQueryStudio',
      component: Vue.component('SegmentStudio', () => import('./pages/segmentation/segmentQueryStudio/segmentQueryStudio')),
      meta: {
        title: 'Segment Query Studio'
      }
    },

    //Segment Studio
    {
      path: '/segment/live',
      name: 'LiveSegmentList',
      component: Vue.component('LiveSegmentList', () => import('./pages/segmentation/segmentList/liveSegments/liveSegmentList')),
      meta: {
        title: 'Live Segments'
      }
    },

    // Create Segment
    {
      path: '/segment/create',
      name: 'createSegment',
      component: Vue.component('SegmentStudio', () => import('./pages/segmentation/createSegment/createSegment')),
      meta: {
        title: 'Segment Query Studio'
      }
    },

    // SEgment Update
    {
      path: '/segment/:id/update',
      name: 'CreateSegment',
      component: Vue.component('SegmentStudio', () => import('./pages/segmentation/createSegment/createSegment')),
      meta: {
        title: 'Update Segment'
      }
    },

    {
      path: '/segment/static-list',
      name: 'StaticList',
      component: Vue.component('StaticList', () => import('./pages/segmentation/segmentList/staticLists/staticList')),
      meta: {
        title: 'Live Segments'
      }
    },

    // Static List Detail
    {
      path: '/segment/static-list/:id',
      name: 'StaticListDetail',
      component: Vue.component('StaticListDetail', () => import('./pages/segmentation/staticSegmentDetail/staticSegmentDetail')),
      meta: {
        title: 'Static SEgment details'
      }
    },

    // Image Assets
    {
      path: '/assets/image',
      name: 'ImageAssetsPage',
      component: Vue.component('ImageAssetsPage', () => import('./pages/imageAssetsPage/imageAssetsPage')),
      meta: {
        title: 'Image Assets'
      }
    },

    // Customer
    {
      path: '/customer/overview',
      name: 'CustomerOverview',
      component: CustomerOverview,
      meta: {
        title: 'Customers'
      }
    },
    {
      path: '/customer',
      name: 'CustomerList',
      component: CustomerList,
      meta: {
        title: 'Customers'
      }
    },
    {
      path: '/customer/detail',
      name: 'CustomerDetails',
      component: CustomerDetails,
      meta: {
        title: 'Customer Detail'
      }
    },
    {
      path: '/customer/upload',
      name: 'CustomersUpload',
      component: CustomersUpload,
      meta: {
        title: 'Customer Upload'
      }
    },
    {
      path: '/customer/uploads',
      name: 'CustomerUploadHistory',
      component: CustomerUploadHistory,
      meta: {
        title: 'Customer Upload History'
      }
    },

    // Customer Profile attributes
    {
      path: '/customer/profile-attributes',
      name: 'CustomerProfileAttributes',
      component: Vue.component('CustomerProfileAttributes', () => import('./pages/customerProfileAttributes/customerProfileAttributes')),
      meta: {
        title: 'Customer Attributes'
      }
    },

    // Integration Panel
    {
      path: '/app-market/:section1?/:section2?/:section3?',
      name: 'IntegrationPanel',
      component: Vue.component('IntegrationsPanel', () => import('./pages/integrationsPanel/integrationsPanel')),
      meta: {
        title: 'App Market'
      }
    },

    // Organization Settings
    {
      path: '/settings/:section1?/:section2?/:section3?',
      name: 'OrganizationSettings',
      component: OrganizationSettings,
      meta: {
        title: 'Organization Settings'
      }
    },

    // Funnel
    {
      path: '/analytics/funnel',
      name: 'Funnel',
      component: Funnel
    },

    // Funnel
    {
      path: '/analytics/retention',
      name: 'Retention',
      component: Retention
    },

    // Journey List - Create
    {
      path: '/journey',
      name: 'JourneyList',
      component: JourneyList
    },

    // Journey - Create
    {
      path: '/journey/create',
      name: 'CreateJourney',
      component: JourneyCreate
    },

    // Journey - Update
    {
      path: '/journey/:id',
      name: 'UpdateJourney',
      component: JourneyUpdate
    },

    // Page Not Found Component
    {
      path: '/404',
      name: '404',
      component: PageNotFound,
      meta: {
        title: 'Page Not Found'
      }
    },

    {
      path: '*',
      name: 'UnknownPage',
      component: PageNotFound,
      meta: {
        title: 'Page Not Found'
      }
    }
  ]
});
