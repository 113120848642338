<template>
  <el-header id="headerMenuContainer" v-show="$store.state.showHeader">
    <el-menu class="headerMenu" v-if="$store.state.showHeader" mode="horizontal">
      <div class="companyLogo" index="Dashboard" @click="$router.push({ name: 'Dashboard' })" style="cursor: pointer">
        <img src="https://static.growlytics.in/dashboard-assets/assets/img/logo/logo_dark_hr.png" />
      </div>

      <!-- Product Updates Icon -->
      <div class="productUpdatesIcon" style="display: inline-block !important; float: right !important; position: relative !important">
        <i class="fa fa-bell" style="position: absolute; top: 3px; left: 0" aria-hidden="true"></i>
      </div>

      <!-- Rightside profile & logout -->
      <el-submenu popper-class="headerDropdownMenu" index="settings" class="settingsMenu">
        <template slot="title">{{ $store.state.token.firstName }} {{ $store.state.token.lastName }}</template>
        <!-- <el-menu-item index="Profile" style=" margin-top: -9px;">Profile</el-menu-item> -->

        <!-- Organization Settings -->
        <el-menu-item index="/settings" @click="$router.push({ name: 'OrganizationSettings' })">Organization Settings</el-menu-item>

        <hr style="margin-top: 7px; margin-bottom: 7px !important; line-height: 0px !important" />

        <!-- User Profile -->
        <!-- <el-menu-item index="/user/detail" @click="$router.push({ name: 'Profile' })">My Profile</el-menu-item> -->

        <!-- Switch Organization -->
        <!-- <el-menu-item index="/user/detail" @click="$router.push({ name: 'SelectCompany' })">Switch Organization</el-menu-item> -->

        <!-- Logout -->
        <el-menu-item index style="color: #f44336; margin-top: 0px" @click="onLogout">Logout</el-menu-item>
      </el-submenu>

      <!-- Project & Environment -->
      <el-button type="info" plain class="projectSelectorButton" v-if="$store.state.token.companyName" @click="$router.push({ name: 'SelectCompany' })">
        {{ $store.state.token.companyName }}
      </el-button>
    </el-menu>
  </el-header>
</template>

<script>
export default {
  name: 'TopMenu',
  data() {
    return {};
  }
};
</script>
