export default {
  methods: {
    marketingIdentifyCustomer(systemId, params) {
      // if (process.env.NODE_ENV === 'production') {
      console.log('Tracking: Customer: ', systemId, params);
      if (window.Growlytics) {
        window.Growlytics.user.identify(systemId, params);
      }

      // Also identify intercom users.
      if (window.Intercom) {
        let payload = {
          email: params.email,
          name: params.firstName + ' ' + params.lastName,
          firstName: params.firstName,
          lastName: params.lastName
        };

        if (params.companyId) {
          payload['Last Company ID'] = params.companyId;
          payload['Last Company Name'] = params.companyName;
        }

        window.Intercom('update', payload);
      }
      // }
    },

    marketingTrackEvent(eventName, params) {
      // if (process.env.NODE_ENV === 'production') {
      // console.log('Tracking: Event: ', eventName, params);
      if (window.Growlytics) {
        window.Growlytics.events.track(eventName, params);
      }

      // Track events on intercom as well.
      if (window.Intercom) {
        window.Intercom('trackEvent', eventName, params);
      }
      // }
    }
  },
  data: function () {
    return {};
  }
};
