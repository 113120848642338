import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';
const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState(), logrocketPlugin],
  state: {},
  getters: {
    // Compute derived state based on the current state. More like computed property.
  },
  mutations: {
    // Mutate the current state
    setHeaderVisiblity(state, payload) {
      state.showHeader = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    }
  },
  actions: {
    // Get data from server and send that to mutations to mutate the current state
    setHeaderVisiblity(context, payload) {
      context.commit('setHeaderVisiblity', payload);
    },
    setToken(context, payload) {
      context.commit('setToken', payload);
    }
  }
});
