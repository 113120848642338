<template>
  <el-container id="app" style="height: 100vh">
    <!-- Side Menu -->

    <!-- <el-aside id="sideMenu" style="width: auto; display: flex; flex-direction: row" v-if="$store.state.showHeader && !showFullScreenLoader">
      <SideMenuComponent></SideMenuComponent>
    </el-aside> -->

    <!-- Sub Side Menu -->
    <el-aside id="sideMenu" style="overflow: hidden; width: auto" v-if="$store.state.showHeader && !showFullScreenLoader">
      <SubSideMenuComponent></SubSideMenuComponent>
    </el-aside>

    <!-- Suspend Warning -->
    <AccountSuspensionWarning></AccountSuspensionWarning>

    <el-container>
      <!-- Top Menu -->
      <!-- <TopMenuComponent></TopMenuComponent> -->

      <!-- Main Page -->
      <el-main id="appDiv">
        <!-- Indiviaual PAge -->
        <transition name="fade" v-if="!showFullScreenLoader">
          <router-view v-bind:class="{ routerPagePadding: $store.state.showHeader }"></router-view>
        </transition>

        <!-- Fetch Settings Loader -->
        <div v-loading="true" v-else style="width: 100%; height: 100vh; background: white">
          <div style="z-index: 9000; position: absolute; text-align: center; width: 100%; margin-top: 75vh">
            <img src="https://static.growlytics.in/dashboard-assets/assets/img/logo_hr.png" style="width: 200px" />
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AppComponent from './appComponent';
export default AppComponent;
</script>

<style lang="scss" src="./main.scss"></style>
