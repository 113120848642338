<template>
  <div v-if="dashboardList">
    <router-link :to="'/'" @click="selectedDashboard = null">
      <div class="menuItem segmentItem" :class="{ selectedItem: selectedDashboard == null }">Sales Performance</div>
    </router-link>

    <div class="border-t mt-3 mb-2 pt-2.5 pl-2 text-xs font-bold text-primary-700">Custom Dashboards</div>

    <draggable
      v-model="dashboardList"
      group="dashboardName"
      @start="drag = true"
      @end="
        drag = false;
        onDashboardOrderChange();
      "
      v-bind="dragDashboardOptions"
      handle=".draggingDasboard"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <router-link :to="'/dashboard/' + dashboard.id" v-for="(dashboard, index) in dashboardList" :key="index">
          <div class="menuItem segmentItem" :class="{ selectedItem: dashboard.id == selectedDashboard }">
            <!-- <div class="changeOrderBtn draggingDasboard">
              <i class="fa fa-bars" style="color: gray"></i>
            </div> -->

            <div class="linkContainer" v-if="!dashboard.editMode">
              {{ dashboard.name }}
            </div>
            <div class="cancelBtnSection" v-else>
              <el-input size="mini" placeholder="Enter dashboard name" v-model="dashboard.name"></el-input>
              <!-- <el-button size="mini" type="primary" plain @click="cancelDashboardEditMode(dashboard)"><i class="el-icon-close"></i></el-button> -->
              &nbsp;
              <i class="el-icon-check cancelBtn" @click="updateDashboardName(dashboard)"></i>
              <i class="el-icon-close cancelBtn" @click="cancelDashboardEditMode(dashboard)"></i>
            </div>

            <div class="rightSideMenu" v-if="!dashboard.editMode">
              <el-dropdown trigger="hover" @command="handleCommand">
                <span class="sideMenuIcon">
                  <i class="el-icon-more fa-rotate-90"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{ action: 'edit', dashboard: dashboard }" icon="el-icon-edit">Edit</el-dropdown-item>
                  <el-dropdown-item
                    :command="{
                      action: 'delete',
                      dashboard: dashboard,
                      index: index
                    }"
                    icon="el-icon-delete"
                    >Delete</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </router-link>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import dashboardModule from '@/services/dashboard';
import draggable from 'vuedraggable';

export default {
  name: 'app',
  data() {
    return { dashboardId: null, drag: false, dragCard: false, selectedDashboard: null, dashboardList: null };
  },
  components: { draggable },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    dragDashboardOptions() {
      return {
        animation: 100,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    }
  },
  methods: {
    async fetchDashboardCardList() {
      try {
        //Read dashbaord list.
        let result = await dashboardModule.fetchDashboardList(this);
        this.dashboardList = result.data.data;

        let dashboardId = this.$route.params.dashboardId;
        if (dashboardId) {
          this.selectedDashboard = dashboardId;
        }
      } catch (err) {
        this.reportError(err);
      }
    },

    handleCommand(command) {
      if (command.action == 'edit') {
        this.editDashboard(command.dashboard);
      }
      if (command.action == 'delete') {
        this.deleteDashboard(command.dashboard, command.index);
      }
      if (command.action == 'editOfCard') {
        this.editDashboardCard(command.card);
      }
      if (command.action == 'deleteOfCard') {
        this.deleteDashboardCard(command.card, command.index);
      }
    },

    onDashboardOrderChange() {
      let dashboardOrder = [];
      for (let i = 0; i < this.dashboardList.length; i++) {
        let dashboardId = this.dashboardList[i].id;
        dashboardOrder.push(dashboardId);
      }

      let params = {
        order: dashboardOrder
      };
      dashboardModule
        .saveDashboardOrder(params, this)
        .then(() => {
          //saved successull
        })
        .catch((error) => {
          this.reportError(error);
          this.errorToast('Something went wrong. Failed to save dashboard order.');
        });
    },

    updateDashboardName(dashboard) {
      if (!dashboard.name) {
        this.errorToast(`Dashboard name can not be empty.`);
        return;
      }
      let params = {
        name: dashboard.name,
        dashboardId: dashboard.id
      };
      dashboardModule
        .updateDashboardById(params, this)
        .then(() => {
          this.dashboardNameMap[dashboard.id] = dashboard.name;
          dashboard.editMode = false;
          this.$forceUpdate();
        })
        .catch((e) => {
          this.reportError(e);
          dashboard.editMode = false;
          this.$forceUpdate();
        });
    },

    editDashboard(dashboard) {
      this.oldEditName = dashboard.name;
      dashboard.editMode = true;
      this.$forceUpdate();
    },

    cancelDashboardEditMode(dashboard) {
      dashboard.name = this.oldEditName;
      dashboard.editMode = false;
      this.$forceUpdate();
    },

    deleteDashboard(dashboard, index) {
      this.$swal({
        title: 'Delete dashboard?',
        text: "You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(() => {
        setTimeout(() => {
          dashboardModule
            .deleteDashboardById(dashboard.id, this)
            .then((result) => {
              if (result.data.success) {
                this.dashboardList.splice(index, 1);
                this.successToast(`Dashboard deleted successfully.`);
                if (index > 0) {
                  this.applyDashboard(this.dashboardList[index - 1]);
                }
              }
            })
            .catch((e) => {
              this.reportError(e);
              this.errorToast(`Something went wrong.`);
            });
        }, 50);
      });
    }
  },

  mounted: function () {
    this.fetchDashboardCardList();
    this.$root.$on('onDashboardListItemChange', () => {
      this.selectedDashboard = this.$route.params.dashboardId;
    });
  }
};
</script>

<style lang="scss">
$paddingTopBottom: 12px;
.segmentItem {
  font-size: 12px !important;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  color: red;
  width: 100%;

  .changeOrderBtn {
    padding-right: 10px;
    font-size: 13px;
    text-align: center;
  }

  .sideMenuIcon {
    border: none;
    // border: 1px solid red;
    margin-right: 5px;
  }

  .linkContainer {
    flex: 1;

    .rightSideMenu {
      float: right;
      border: 1px solid red;

      .el-dropdown {
        margin-top: 10px;
        margin-right: 6px;
      }
    }

    .el-button {
      display: inline-block;
      margin: 0px;
      padding: 0px;
      padding-top: 3px;
      padding-bottom: 3px;
      color: #355b6d;
      //   padding-bottom: $paddingTopBottom;
      //   padding-top: $paddingTopBottom;
    }
  }

  .cancelBtnSection {
    display: inherit;

    .cancelBtn {
      //   border: 1px solid red !important;
      font-size: 14px;
      line-height: 26px;
      padding: 0px 3px;
      border: none;
    }
  }

  &:hover {
    // background: #f7f8fa;
    cursor: pointer;
  }
}
</style>
