const axios = require('axios');
const { baseUrlNew, analyticsUrl, buildAuthHeaders } = require('./settings.js');

exports.fetchDashboardList = function () {
  return axios.get(baseUrlNew + `dashboard`, {
    headers: buildAuthHeaders()
  });
};

exports.fetchDashboardCardList = function (dashboardId) {
  return axios.get(baseUrlNew + `dashboard/${dashboardId}/cards`, {
    headers: buildAuthHeaders()
  });
};

exports.createCard = function (params) {
  return axios.post(baseUrlNew + `dashboard/card`, params, {
    headers: buildAuthHeaders()
  });
};

exports.updateDashboardById = function (params) {
  return axios.put(baseUrlNew + `dashboard`, params, {
    headers: buildAuthHeaders()
  });
};

exports.deleteDashboardById = function (dashboardId) {
  return axios.delete(baseUrlNew + `dashboard/${dashboardId}`, {
    headers: buildAuthHeaders()
  });
};

exports.updateDashboardCardById = function (params) {
  return axios.put(baseUrlNew + `dashboard/card`, params, {
    headers: buildAuthHeaders()
  });
};

exports.deleteDashboardCardById = function (cardId) {
  return axios.delete(baseUrlNew + `dashboard/card/${cardId}`, {
    headers: buildAuthHeaders()
  });
};

exports.saveDashboardOrder = function (params) {
  return axios.put(baseUrlNew + `dashboard/order`, params, {
    headers: buildAuthHeaders()
  });
};

exports.saveDashboardCardOrder = function (params) {
  return axios.put(baseUrlNew + `dashboard/card/order`, params, {
    headers: buildAuthHeaders()
  });
};

exports.fetchDashboardCardData = function (params) {
  return axios.post(analyticsUrl + `dashboard/data`, params, {
    headers: buildAuthHeaders()
  });
};

exports.fetchHardcodedDashboardData = function (params) {
  return axios.post(analyticsUrl + `dashboard/hardcoded/data`, params, {
    headers: buildAuthHeaders()
  });
};

exports.fetchLifecycleStagesData = function (params) {
  return axios.post(analyticsUrl + `dashboard/hardcoded/lifecycle-insights`, params, {
    headers: buildAuthHeaders()
  });
};

exports.encryptQuery = function (params) {
  return axios.post(analyticsUrl + `enc-query`, params, {
    headers: buildAuthHeaders()
  });
};

exports.decryptQuery = function (params) {
  return axios.post(analyticsUrl + `dec-query`, params, {
    headers: buildAuthHeaders()
  });
};
