const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.createSessionForThatCompany = function (companyId) {
  return axios.get(baseUrlNew + `company/auth/update/${companyId}`, {
    headers: buildAuthHeaders()
  });
};

exports.fetchCompaniesOfUser = function () {
  return axios.get(baseUrlNew + `company`, {
    headers: buildAuthHeaders()
  });
};

exports.createCompany = function (params) {
  return axios.post(baseUrlNew + `company`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getCompanySetupStatus = function () {
  return axios.get(baseUrlNew + 'settings/setup', {
    headers: buildAuthHeaders()
  });
};

exports.saveCompanyMetadata = function (params) {
  return axios.post(baseUrlNew + 'settings', params, {
    headers: buildAuthHeaders()
  });
};

exports.getCompanyMetadata = function () {
  return axios.get(baseUrlNew + 'settings', {
    headers: buildAuthHeaders()
  });
};

exports.saveDefaultEvents = function (params) {
  return axios.post(baseUrlNew + 'settings/default-events', params, {
    headers: buildAuthHeaders()
  });
};

exports.getDefaultEventSettings = function (eventType) {
  return axios.get(baseUrlNew + `settings/default-events/${eventType}`, {
    headers: buildAuthHeaders()
  });
};

exports.getGeneralSettings = function () {
  return axios.get(baseUrlNew + 'settings/integration', {
    headers: buildAuthHeaders()
  });
};

exports.getCompanySettingByKey = function (key) {
  return axios.get(baseUrlNew + `settings/bykey/${key}`, {
    headers: buildAuthHeaders()
  });
};

exports.deleteCompany = function () {
  return axios.delete(baseUrlNew + `company/delete`, {
    headers: buildAuthHeaders()
  });
};
