var numeral = require('numeral');
let prettyBytes = require('../services/utils/preetyBytes');
import * as Sentry from '@sentry/vue';
import humanizeDuration from 'humanize-duration';
import moment from 'moment';
const prettyMilliseconds = require('pretty-ms');
import companyModule from '@/services/company';

let customFonts = null;

export default {
  methods: {
    /******************************Start: Reusable Methods ******************************/

    showFacebookSync() {
      let allowed = ['1xh60ck8qskj18ygus', '5ab58owcl8lfle9h'];
      if (allowed.indexOf(this.$store.state.token.projectId) >= 0) {
        return true;
      }
      return false;
    },

    getCurrentMenuSection() {
      if (location.pathname.startsWith('/customer') || location.pathname.startsWith('/segment')) {
        return 'Customers';
      } else if (location.pathname.startsWith('/campaign') || location.pathname.startsWith('/webMessage') || location.pathname.startsWith('/journey') || location.pathname.startsWith('/remarketing') || location.pathname.startsWith('/coupon') || location.pathname.startsWith('/assets/image')) {
        return 'Campaigns';
      } else if (location.pathname.startsWith('/analytics')) {
        return 'Analytics';
      } else if (location.pathname.startsWith('/setting')) {
        return 'Settings';
      } else if (location.pathname.startsWith('/scheduled-report')) {
        return 'Scheduled Reports';
      } else if (location.pathname.startsWith('/app-market')) {
        return 'App Integrations';
      } else {
        return 'Dashboard';
      }
    },

    async validateForm(form) {
      return new Promise((resolve) => {
        form.validate((valid) => {
          let isInvalid = valid === false;
          resolve(!isInvalid);
        });
      });
    },

    overrideJson(original, modified) {
      var isObjectAndNotArray = function (object) {
        return typeof object === 'object' && !Array.isArray(object);
      };

      // 'createNew' defaults to false
      var overwriteKeys = function (baseObject, overrideObject, createNew) {
        if (!baseObject) {
          baseObject = {};
        }
        if (createNew) {
          baseObject = JSON.parse(JSON.stringify(baseObject));
        }
        if (!overrideObject) return overrideObject;
        Object.keys(overrideObject).forEach(function (key) {
          if (isObjectAndNotArray(baseObject[key]) && isObjectAndNotArray(overrideObject[key])) {
            overwriteKeys(baseObject[key], overrideObject[key]);
          } else {
            baseObject[key] = overrideObject[key];
          }
        });
        return baseObject;
      };

      return overwriteKeys(original, modified, false);
    },

    getPrettyMilliseconds(milliSeconds, fullText) {
      try {
        milliSeconds = parseInt(milliSeconds);
        if (isNaN(milliSeconds)) {
          return '0 ms';
        }
        return prettyMilliseconds(milliSeconds, {
          keepDecimalsOnWholeSeconds: true,
          verbose: fullText == null || fullText === undefined ? true : fullText
        });
      } catch (e) {
        console.error(e);
        return null;
      }
    },

    //Get descriptive time difference
    getReleventTime(date, skipAgoSuffix) {
      try {
        parseInt(date);
        if (!date) {
          return 'N/A';
        }
        return moment(date, 'x').fromNow(skipAgoSuffix);
      } catch (e) {
        console.error(e);
        return 'N/A';
      }
    },

    onGoToPage(key, keyPath) {
      console.log(key, keyPath);
      this.$router.push({
        name: key
      });
    },

    //Show no data toast message
    noData(warnMsg) {
      this.$message({
        type: 'warning',
        message: warnMsg
      });
    },

    //Show error toast message
    errorToast(errorMsg, duration) {
      this.$message.error({
        message: errorMsg,
        showClose: true,
        duration: duration ? duration : 5000
      });
    },

    successToast(message, duration) {
      this.$message({
        type: 'success',
        message: message,
        showClose: true,
        duration: duration ? duration : 5000
      });
    },

    warningToast(message, duration) {
      this.$message({
        type: 'warning',
        message: message,
        showClose: true,
        duration: duration ? duration : 5000
      });
    },

    infoToast(message, duration) {
      this.$message({
        message: message,
        showClose: true,
        duration: duration ? duration : 5000
      });
    },

    getJsonValueIfAvailable(str) {
      if (!str) return null;
      if (typeof str === 'object' || str.constructor === Array) return str;
      if (typeof str !== 'string') return null;
      try {
        return JSON.parse(str);
      } catch (e) {
        return null;
      }
    },

    getBytesDisplayText(bytes, isInKb) {
      try {
        bytes = parseInt(bytes);
        if (!isInKb) {
          bytes = bytes / 1000;
        }
        return prettyBytes(bytes);
      } catch (e) {
        return;
      }
    },

    getNumberWithComma(number) {
      if (!number) return number;
      return numeral(number).format('0,0');
    },

    getNumberSuffix(number) {
      var n = number.toString().split('.')[0];
      var lastDigits = n.substring(n.length - 2);
      //add exception just for 11, 12 and 13
      if (lastDigits === '11' || lastDigits === '12' || lastDigits === '13') {
        return number + 'th';
      }
      switch (n.substring(n.length - 1)) {
        case '1':
          return number + 'st';
        case '2':
          return number + 'nd';
        case '3':
          return number + 'rd';
        default:
          return number + 'th';
      }
    },

    /******************************End: Reusable Methods ******************************/

    /******************************Start: Event Related Methods ******************************/
    getEventDisplayDate(date) {
      var dt = moment(date, 'x');
      return dt.format('Do MMMM YYYY');
    },

    getEventDisplayTime(date) {
      var dt = moment(date, 'x');
      return dt.format('hh:mm:ss A');
    },

    getCustomerDisplayDate(date) {
      var dt = moment(date, 'x');
      return dt.format('MMM Do, YYYY');
    },

    getCampaignDisplayTime(date) {
      var dt = moment(date, 'x');
      return dt.format('DD-MMM-YYYY hh:mm:ss A');
    },

    getUptimeDisplayTime(date) {
      var dt = moment(date, 'x');
      return dt.format('DD-MMM-YYYY hh:mm:ss A');
    },

    getReadableDuration(minutes, options) {
      options = options
        ? options
        : {
            largest: 2,
            round: true
          };
      return humanizeDuration(minutes, options);
    },

    getReadableMinuteDuration(minutes) {
      return humanizeDuration(minutes, {
        round: true,
        units: ['m']
      });
    },

    getGroupByDurationList(duration) {
      duration = parseInt(duration);
      if (duration <= 2880) {
        // hour, minute
        return [
          {
            key: 'Hourly',
            value: 'hour'
          }
        ];
      } else if (duration > 2880 && duration <= 10080) {
        // day, hour
        return [
          {
            key: 'Daily',
            value: 'day'
          },
          {
            key: 'Hourly',
            value: 'hour'
          }
        ];
      } else if (duration > 10080 && duration <= 43200) {
        // week, day
        return [
          {
            key: 'Daily',
            value: 'day'
          },
          {
            key: 'Weekly',
            value: 'week'
          }
        ];
      } else if (duration > 43200) {
        // week, day, monthly
        return [
          {
            key: 'Daily',
            value: 'day'
          },
          {
            key: 'Weekly',
            value: 'week'
          },
          {
            key: 'Monthly',
            value: 'month'
          }
        ];
      }
      throw new Error('Invalid duration.');
    },

    getCountryMapArray() {
      return [
        {
          Code: 'AF',
          Name: 'Afghanistan'
        },
        {
          Code: 'AX',
          Name: '\u00c5land Islands'
        },
        {
          Code: 'AL',
          Name: 'Albania'
        },
        {
          Code: 'DZ',
          Name: 'Algeria'
        },
        {
          Code: 'AS',
          Name: 'American Samoa'
        },
        {
          Code: 'AD',
          Name: 'Andorra'
        },
        {
          Code: 'AO',
          Name: 'Angola'
        },
        {
          Code: 'AI',
          Name: 'Anguilla'
        },
        {
          Code: 'AQ',
          Name: 'Antarctica'
        },
        {
          Code: 'AG',
          Name: 'Antigua and Barbuda'
        },
        {
          Code: 'AR',
          Name: 'Argentina'
        },
        {
          Code: 'AM',
          Name: 'Armenia'
        },
        {
          Code: 'AW',
          Name: 'Aruba'
        },
        {
          Code: 'AU',
          Name: 'Australia'
        },
        {
          Code: 'AT',
          Name: 'Austria'
        },
        {
          Code: 'AZ',
          Name: 'Azerbaijan'
        },
        {
          Code: 'BS',
          Name: 'Bahamas'
        },
        {
          Code: 'BH',
          Name: 'Bahrain'
        },
        {
          Code: 'BD',
          Name: 'Bangladesh'
        },
        {
          Code: 'BB',
          Name: 'Barbados'
        },
        {
          Code: 'BY',
          Name: 'Belarus'
        },
        {
          Code: 'BE',
          Name: 'Belgium'
        },
        {
          Code: 'BZ',
          Name: 'Belize'
        },
        {
          Code: 'BJ',
          Name: 'Benin'
        },
        {
          Code: 'BM',
          Name: 'Bermuda'
        },
        {
          Code: 'BT',
          Name: 'Bhutan'
        },
        {
          Code: 'BO',
          Name: 'Bolivia, Plurinational State of'
        },
        {
          Code: 'BQ',
          Name: 'Bonaire, Sint Eustatius and Saba'
        },
        {
          Code: 'BA',
          Name: 'Bosnia and Herzegovina'
        },
        {
          Code: 'BW',
          Name: 'Botswana'
        },
        {
          Code: 'BV',
          Name: 'Bouvet Island'
        },
        {
          Code: 'BR',
          Name: 'Brazil'
        },
        {
          Code: 'IO',
          Name: 'British Indian Ocean Territory'
        },
        {
          Code: 'BN',
          Name: 'Brunei Darussalam'
        },
        {
          Code: 'BG',
          Name: 'Bulgaria'
        },
        {
          Code: 'BF',
          Name: 'Burkina Faso'
        },
        {
          Code: 'BI',
          Name: 'Burundi'
        },
        {
          Code: 'KH',
          Name: 'Cambodia'
        },
        {
          Code: 'CM',
          Name: 'Cameroon'
        },
        {
          Code: 'CA',
          Name: 'Canada'
        },
        {
          Code: 'CV',
          Name: 'Cape Verde'
        },
        {
          Code: 'KY',
          Name: 'Cayman Islands'
        },
        {
          Code: 'CF',
          Name: 'Central African Republic'
        },
        {
          Code: 'TD',
          Name: 'Chad'
        },
        {
          Code: 'CL',
          Name: 'Chile'
        },
        {
          Code: 'CN',
          Name: 'China'
        },
        {
          Code: 'CX',
          Name: 'Christmas Island'
        },
        {
          Code: 'CC',
          Name: 'Cocos (Keeling) Islands'
        },
        {
          Code: 'CO',
          Name: 'Colombia'
        },
        {
          Code: 'KM',
          Name: 'Comoros'
        },
        {
          Code: 'CG',
          Name: 'Congo'
        },
        {
          Code: 'CD',
          Name: 'Congo, the Democratic Republic of the'
        },
        {
          Code: 'CK',
          Name: 'Cook Islands'
        },
        {
          Code: 'CR',
          Name: 'Costa Rica'
        },
        {
          Code: 'CI',
          Name: "C\u00f4te d'Ivoire"
        },
        {
          Code: 'HR',
          Name: 'Croatia'
        },
        {
          Code: 'CU',
          Name: 'Cuba'
        },
        {
          Code: 'CW',
          Name: 'Cura\u00e7ao'
        },
        {
          Code: 'CY',
          Name: 'Cyprus'
        },
        {
          Code: 'CZ',
          Name: 'Czech Republic'
        },
        {
          Code: 'DK',
          Name: 'Denmark'
        },
        {
          Code: 'DJ',
          Name: 'Djibouti'
        },
        {
          Code: 'DM',
          Name: 'Dominica'
        },
        {
          Code: 'DO',
          Name: 'Dominican Republic'
        },
        {
          Code: 'EC',
          Name: 'Ecuador'
        },
        {
          Code: 'EG',
          Name: 'Egypt'
        },
        {
          Code: 'SV',
          Name: 'El Salvador'
        },
        {
          Code: 'GQ',
          Name: 'Equatorial Guinea'
        },
        {
          Code: 'ER',
          Name: 'Eritrea'
        },
        {
          Code: 'EE',
          Name: 'Estonia'
        },
        {
          Code: 'ET',
          Name: 'Ethiopia'
        },
        {
          Code: 'FK',
          Name: 'Falkland Islands (Malvinas)'
        },
        {
          Code: 'FO',
          Name: 'Faroe Islands'
        },
        {
          Code: 'FJ',
          Name: 'Fiji'
        },
        {
          Code: 'FI',
          Name: 'Finland'
        },
        {
          Code: 'FR',
          Name: 'France'
        },
        {
          Code: 'GF',
          Name: 'French Guiana'
        },
        {
          Code: 'PF',
          Name: 'French Polynesia'
        },
        {
          Code: 'TF',
          Name: 'French Southern Territories'
        },
        {
          Code: 'GA',
          Name: 'Gabon'
        },
        {
          Code: 'GM',
          Name: 'Gambia'
        },
        {
          Code: 'GE',
          Name: 'Georgia'
        },
        {
          Code: 'DE',
          Name: 'Germany'
        },
        {
          Code: 'GH',
          Name: 'Ghana'
        },
        {
          Code: 'GI',
          Name: 'Gibraltar'
        },
        {
          Code: 'GR',
          Name: 'Greece'
        },
        {
          Code: 'GL',
          Name: 'Greenland'
        },
        {
          Code: 'GD',
          Name: 'Grenada'
        },
        {
          Code: 'GP',
          Name: 'Guadeloupe'
        },
        {
          Code: 'GU',
          Name: 'Guam'
        },
        {
          Code: 'GT',
          Name: 'Guatemala'
        },
        {
          Code: 'GG',
          Name: 'Guernsey'
        },
        {
          Code: 'GN',
          Name: 'Guinea'
        },
        {
          Code: 'GW',
          Name: 'Guinea-Bissau'
        },
        {
          Code: 'GY',
          Name: 'Guyana'
        },
        {
          Code: 'HT',
          Name: 'Haiti'
        },
        {
          Code: 'HM',
          Name: 'Heard Island and McDonald Islands'
        },
        {
          Code: 'VA',
          Name: 'Holy See (Vatican City State)'
        },
        {
          Code: 'HN',
          Name: 'Honduras'
        },
        {
          Code: 'HK',
          Name: 'Hong Kong'
        },
        {
          Code: 'HU',
          Name: 'Hungary'
        },
        {
          Code: 'IS',
          Name: 'Iceland'
        },
        {
          Code: 'IN',
          Name: 'India'
        },
        {
          Code: 'ID',
          Name: 'Indonesia'
        },
        {
          Code: 'IR',
          Name: 'Iran, Islamic Republic of'
        },
        {
          Code: 'IQ',
          Name: 'Iraq'
        },
        {
          Code: 'IE',
          Name: 'Ireland'
        },
        {
          Code: 'IM',
          Name: 'Isle of Man'
        },
        {
          Code: 'IL',
          Name: 'Israel'
        },
        {
          Code: 'IT',
          Name: 'Italy'
        },
        {
          Code: 'JM',
          Name: 'Jamaica'
        },
        {
          Code: 'JP',
          Name: 'Japan'
        },
        {
          Code: 'JE',
          Name: 'Jersey'
        },
        {
          Code: 'JO',
          Name: 'Jordan'
        },
        {
          Code: 'KZ',
          Name: 'Kazakhstan'
        },
        {
          Code: 'KE',
          Name: 'Kenya'
        },
        {
          Code: 'KI',
          Name: 'Kiribati'
        },
        {
          Code: 'KP',
          Name: "Korea, Democratic People's Republic of"
        },
        {
          Code: 'KR',
          Name: 'Korea, Republic of'
        },
        {
          Code: 'KW',
          Name: 'Kuwait'
        },
        {
          Code: 'KG',
          Name: 'Kyrgyzstan'
        },
        {
          Code: 'LA',
          Name: "Lao People's Democratic Republic"
        },
        {
          Code: 'LV',
          Name: 'Latvia'
        },
        {
          Code: 'LB',
          Name: 'Lebanon'
        },
        {
          Code: 'LS',
          Name: 'Lesotho'
        },
        {
          Code: 'LR',
          Name: 'Liberia'
        },
        {
          Code: 'LY',
          Name: 'Libya'
        },
        {
          Code: 'LI',
          Name: 'Liechtenstein'
        },
        {
          Code: 'LT',
          Name: 'Lithuania'
        },
        {
          Code: 'LU',
          Name: 'Luxembourg'
        },
        {
          Code: 'MO',
          Name: 'Macao'
        },
        {
          Code: 'MK',
          Name: 'Macedonia, the Former Yugoslav Republic of'
        },
        {
          Code: 'MG',
          Name: 'Madagascar'
        },
        {
          Code: 'MW',
          Name: 'Malawi'
        },
        {
          Code: 'MY',
          Name: 'Malaysia'
        },
        {
          Code: 'MV',
          Name: 'Maldives'
        },
        {
          Code: 'ML',
          Name: 'Mali'
        },
        {
          Code: 'MT',
          Name: 'Malta'
        },
        {
          Code: 'MH',
          Name: 'Marshall Islands'
        },
        {
          Code: 'MQ',
          Name: 'Martinique'
        },
        {
          Code: 'MR',
          Name: 'Mauritania'
        },
        {
          Code: 'MU',
          Name: 'Mauritius'
        },
        {
          Code: 'YT',
          Name: 'Mayotte'
        },
        {
          Code: 'MX',
          Name: 'Mexico'
        },
        {
          Code: 'FM',
          Name: 'Micronesia, Federated States of'
        },
        {
          Code: 'MD',
          Name: 'Moldova, Republic of'
        },
        {
          Code: 'MC',
          Name: 'Monaco'
        },
        {
          Code: 'MN',
          Name: 'Mongolia'
        },
        {
          Code: 'ME',
          Name: 'Montenegro'
        },
        {
          Code: 'MS',
          Name: 'Montserrat'
        },
        {
          Code: 'MA',
          Name: 'Morocco'
        },
        {
          Code: 'MZ',
          Name: 'Mozambique'
        },
        {
          Code: 'MM',
          Name: 'Myanmar'
        },
        {
          Code: 'NA',
          Name: 'Namibia'
        },
        {
          Code: 'NR',
          Name: 'Nauru'
        },
        {
          Code: 'NP',
          Name: 'Nepal'
        },
        {
          Code: 'NL',
          Name: 'Netherlands'
        },
        {
          Code: 'NC',
          Name: 'New Caledonia'
        },
        {
          Code: 'NZ',
          Name: 'New Zealand'
        },
        {
          Code: 'NI',
          Name: 'Nicaragua'
        },
        {
          Code: 'NE',
          Name: 'Niger'
        },
        {
          Code: 'NG',
          Name: 'Nigeria'
        },
        {
          Code: 'NU',
          Name: 'Niue'
        },
        {
          Code: 'NF',
          Name: 'Norfolk Island'
        },
        {
          Code: 'MP',
          Name: 'Northern Mariana Islands'
        },
        {
          Code: 'NO',
          Name: 'Norway'
        },
        {
          Code: 'OM',
          Name: 'Oman'
        },
        {
          Code: 'PK',
          Name: 'Pakistan'
        },
        {
          Code: 'PW',
          Name: 'Palau'
        },
        {
          Code: 'PS',
          Name: 'Palestine, State of'
        },
        {
          Code: 'PA',
          Name: 'Panama'
        },
        {
          Code: 'PG',
          Name: 'Papua New Guinea'
        },
        {
          Code: 'PY',
          Name: 'Paraguay'
        },
        {
          Code: 'PE',
          Name: 'Peru'
        },
        {
          Code: 'PH',
          Name: 'Philippines'
        },
        {
          Code: 'PN',
          Name: 'Pitcairn'
        },
        {
          Code: 'PL',
          Name: 'Poland'
        },
        {
          Code: 'PT',
          Name: 'Portugal'
        },
        {
          Code: 'PR',
          Name: 'Puerto Rico'
        },
        {
          Code: 'QA',
          Name: 'Qatar'
        },
        {
          Code: 'RE',
          Name: 'R\u00e9union'
        },
        {
          Code: 'RO',
          Name: 'Romania'
        },
        {
          Code: 'RU',
          Name: 'Russian Federation'
        },
        {
          Code: 'RW',
          Name: 'Rwanda'
        },
        {
          Code: 'BL',
          Name: 'Saint Barth\u00e9lemy'
        },
        {
          Code: 'SH',
          Name: 'Saint Helena, Ascension and Tristan da Cunha'
        },
        {
          Code: 'KN',
          Name: 'Saint Kitts and Nevis'
        },
        {
          Code: 'LC',
          Name: 'Saint Lucia'
        },
        {
          Code: 'MF',
          Name: 'Saint Martin (French part)'
        },
        {
          Code: 'PM',
          Name: 'Saint Pierre and Miquelon'
        },
        {
          Code: 'VC',
          Name: 'Saint Vincent and the Grenadines'
        },
        {
          Code: 'WS',
          Name: 'Samoa'
        },
        {
          Code: 'SM',
          Name: 'San Marino'
        },
        {
          Code: 'ST',
          Name: 'Sao Tome and Principe'
        },
        {
          Code: 'SA',
          Name: 'Saudi Arabia'
        },
        {
          Code: 'SN',
          Name: 'Senegal'
        },
        {
          Code: 'RS',
          Name: 'Serbia'
        },
        {
          Code: 'SC',
          Name: 'Seychelles'
        },
        {
          Code: 'SL',
          Name: 'Sierra Leone'
        },
        {
          Code: 'SG',
          Name: 'Singapore'
        },
        {
          Code: 'SX',
          Name: 'Sint Maarten (Dutch part)'
        },
        {
          Code: 'SK',
          Name: 'Slovakia'
        },
        {
          Code: 'SI',
          Name: 'Slovenia'
        },
        {
          Code: 'SB',
          Name: 'Solomon Islands'
        },
        {
          Code: 'SO',
          Name: 'Somalia'
        },
        {
          Code: 'ZA',
          Name: 'South Africa'
        },
        {
          Code: 'GS',
          Name: 'South Georgia and the South Sandwich Islands'
        },
        {
          Code: 'SS',
          Name: 'South Sudan'
        },
        {
          Code: 'ES',
          Name: 'Spain'
        },
        {
          Code: 'LK',
          Name: 'Sri Lanka'
        },
        {
          Code: 'SD',
          Name: 'Sudan'
        },
        {
          Code: 'SR',
          Name: 'Suriname'
        },
        {
          Code: 'SJ',
          Name: 'Svalbard and Jan Mayen'
        },
        {
          Code: 'SZ',
          Name: 'Swaziland'
        },
        {
          Code: 'SE',
          Name: 'Sweden'
        },
        {
          Code: 'CH',
          Name: 'Switzerland'
        },
        {
          Code: 'SY',
          Name: 'Syrian Arab Republic'
        },
        {
          Code: 'TW',
          Name: 'Taiwan, Province of China'
        },
        {
          Code: 'TJ',
          Name: 'Tajikistan'
        },
        {
          Code: 'TZ',
          Name: 'Tanzania, United Republic of'
        },
        {
          Code: 'TH',
          Name: 'Thailand'
        },
        {
          Code: 'TL',
          Name: 'Timor-Leste'
        },
        {
          Code: 'TG',
          Name: 'Togo'
        },
        {
          Code: 'TK',
          Name: 'Tokelau'
        },
        {
          Code: 'TO',
          Name: 'Tonga'
        },
        {
          Code: 'TT',
          Name: 'Trinidad and Tobago'
        },
        {
          Code: 'TN',
          Name: 'Tunisia'
        },
        {
          Code: 'TR',
          Name: 'Turkey'
        },
        {
          Code: 'TM',
          Name: 'Turkmenistan'
        },
        {
          Code: 'TC',
          Name: 'Turks and Caicos Islands'
        },
        {
          Code: 'TV',
          Name: 'Tuvalu'
        },
        {
          Code: 'UG',
          Name: 'Uganda'
        },
        {
          Code: 'UA',
          Name: 'Ukraine'
        },
        {
          Code: 'AE',
          Name: 'United Arab Emirates'
        },
        {
          Code: 'GB',
          Name: 'United Kingdom'
        },
        {
          Code: 'US',
          Name: 'United States'
        },
        {
          Code: 'UM',
          Name: 'United States Minor Outlying Islands'
        },
        {
          Code: 'UY',
          Name: 'Uruguay'
        },
        {
          Code: 'UZ',
          Name: 'Uzbekistan'
        },
        {
          Code: 'VU',
          Name: 'Vanuatu'
        },
        {
          Code: 'VE',
          Name: 'Venezuela, Bolivarian Republic of'
        },
        {
          Code: 'VN',
          Name: 'Viet Nam'
        },
        {
          Code: 'VG',
          Name: 'Virgin Islands, British'
        },
        {
          Code: 'VI',
          Name: 'Virgin Islands, U.S.'
        },
        {
          Code: 'WF',
          Name: 'Wallis and Futuna'
        },
        {
          Code: 'EH',
          Name: 'Western Sahara'
        },
        {
          Code: 'YE',
          Name: 'Yemen'
        },
        {
          Code: 'ZM',
          Name: 'Zambia'
        },
        {
          Code: 'ZW',
          Name: 'Zimbabwe'
        }
      ];
    },

    buildEventTitle(event) {
      //   return event. this.getEventDisplayType(event.eventType);
      if (event.event_type == 'page_visit') {
        return 'Page Visit';
      } else if (event.event_type == 'utm_visited') {
        return 'UTM Visited';
      } else if (event.event_type == 'navigation') {
        return 'Navigation';
      } else if (event.event_type == 'app_launched') {
        return 'App Launched';
      } else if (event.event_type == 'app_installed') {
        return 'App Installed';
      } else if (event.event_type == 'app_uninstalled') {
        return 'App Uninstalled';
      }
      if (event.event_type == 'mouse_click') {
        let str = 'Mouse Click';
        if (event.event_data.element_content) {
          str = str + ' - ' + event.event_data.element_content;
        }
        return str;
      } else if (event.event_type == 'new_input') {
        var value = 'New Input --> ' + event.event_data.value;
        if (event.event_data.previousValue && event.event_data.previousValue != '') {
          value += ', Previous --> ' + event.event_data.previousValue;
        }
        return value;
      } else {
        return event.event_type;
      }
    },

    // Location display text
    getLocationDisplayText(city, region, country, country_name) {
      let str = [];
      if (city) str.push(city);
      if (region) str.push(region);

      if (country_name) {
        str.push(country_name);
      } else if (country) {
        str.push(country);
      }

      return str.join(', ');
    },

    getCountryFlagImage(countryCode) {
      return "url('https://static.growlytics.in/dashboard-assets/assets/img/country_flags/" + countryCode.toLowerCase() + ".svg')";
    },

    toPascalCase(string) {
      if (!string) return string;

      let list = string.split(' ');
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          list[i] = list[i].charAt(0).toUpperCase() + list[i].slice(1);
        }
      }

      return list.join(' ');
    },

    /******************************End: Event Related Methods ******************************/

    /******************************Start: Util Methods *************************************/

    reportError(error) {
      if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
        Sentry.captureException(error);
      } else {
        console.error(error);
      }
    }

    /******************************End: Util Methods *************************************/
  },
  data: function () {
    return {
      allSystemEvents: ['New Sessions', 'Page Visited', 'App Launched', 'UTM Visited', 'App Installed', 'App Uninstalled', 'Gave Input', 'Clicked On', 'Notification Clicked', 'Notification Delivered', 'Notification Sent'],
      grwDefaultEventList: ['New Sessions', 'Page Visited', 'App Launched', 'UTM Visited', 'App Installed', 'App Uninstalled'],
      eventListPageDefaultEvents: ['New Sessions', 'Page Visited', 'App Installed', 'App Uninstalled', 'App Launched'],
      grwChartTypeWiseDefaultEvents: {
        rfm: ['New Sessions', 'App Launched']
      },
      grwChannelWiseAllowedDefaultEvents: {
        email: [],
        mobilePush: [],
        webPush: [],
        sms: [],
        inApp: ['App Installed', 'App Launched'],
        journey: ['New Sessions', 'App Installed', 'App Launched'],
        webSocialProof: ['Page Visited']
      },
      filterTimingOptions: {
        10: 'Last 10 Mins',
        30: 'Last 30 Mins',
        60: 'Last 1 Hour',
        180: 'Last 3 Hour',
        360: 'Last 6 Hours',
        1440: 'Last 24 Hours',
        2880: 'Last 2 Days',
        '': 'Custom'
      },
      sessionTimingOptions: {
        1440: 'Last 24 Hours',
        2880: 'Last 2 Days',
        10080: 'Last 7 Days',
        21600: 'Last 15 Days',
        43200: 'Last 30 Days',
        '': 'Custom'
      },
      rfmTimingOptions: {
        43200: 'Last 30 Days',
        64800: 'Last 45 Days',
        129600: 'Last 3 Months',
        172800: 'Last 4 Months',
        216000: 'Last 5 Months',
        259200: 'Last 6 Months',
        388800: 'Last 9 Months',
        518400: 'Last 12 Months',
        777600: 'Last 18 Months',
        1036800: 'Last 24 Months',
        '': 'Custom'
      },
      chartBuilderTimingOptions: {
        1440: 'Last 24 Hours',
        2880: 'Last 2 Days',
        10080: 'Last 7 Days',
        21600: 'Last 15 Days',
        43200: 'Last 30 Days',
        '': 'Custom'
      },
      uptimeOptions: {
        180: 'Last 3 hours',
        1440: 'Last 24 Hours',
        2880: 'Last 2 Days',
        10080: 'Last 7 Days',
        21600: 'Last 15 Days',
        43200: 'Last 30 Days',
        '': 'Custom'
      },
      errorTimingOptions: {
        60: 'Last 1 Hour',
        180: 'Last 3 Hours',
        360: 'Last 6 Hours',
        1440: 'Last 1 Day',
        2880: 'Last 2 Days',
        10080: 'Last Week'
      },
      dashboardTimingOptions: {
        60: 'Last 1 Hour',
        180: 'Last 3 Hours',
        360: 'Last 6 Hours',
        1440: 'Last 1 Day',
        2880: 'Last 2 Days',
        10080: 'Last Week'
      },
      scheduledReportsTimingOptions: {
        1440: 'Last 24 Hours',
        2880: 'Last 2 Days',
        10080: 'Last 7 Days',
        21600: 'Last 15 Days',
        43200: 'Last 30 Days',
        64800: 'Last 45 Days',
        129600: 'Last 3 Months',
        172800: 'Last 4 Months',
        216000: 'Last 5 Months',
        259200: 'Last 6 Months'
      },
      datePickerShortcutList: {
        size: 'mini',
        format: 'dd-MM-yyyy HH:mm:ss',
        shortcuts: [
          {
            text: 'Last 10 Mins',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - (3600 * 1000) / 6);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 30 Mins',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - (3600 * 1000) / 2);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 1 Hour',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 2 Hours',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 2);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 3 Hours',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 3);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 6 Hours',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 6);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 24 Hours',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 2 Days',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 4 Days',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 4);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last Week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      chartBuilderDatePickerShortcutList: {
        size: 'mini',
        format: 'dd-MM-yyyy HH:mm:ss',
        shortcuts: [
          {
            text: 'Last 3 Hours',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 3);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 6 Hours',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 6);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 1 Day',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 2 Days',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 4 Days',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 4);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 7 Days',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 15 Days',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 1 Month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 3 Months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 6 Months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 1 Year',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 360);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      httpMethodList: [
        {
          key: 'GET',
          label: 'Get'
        },
        {
          key: 'POST',
          label: 'Post'
        },
        {
          key: 'PUT',
          label: 'Put'
        },
        {
          key: 'DELETE',
          label: 'Delete'
        },
        {
          key: 'OPTIONS',
          label: 'Options'
        }
      ],

      httpResponseList: [
        {
          key: 'success',
          label: 'Success'
        },
        {
          key: 'failure',
          label: 'Failure'
        }
      ],

      registrationStatus: [
        {
          key: 'registered',
          label: 'Registered'
        },
        {
          key: 'unregistered',
          label: 'Not registered'
        }
      ],

      errorStatusList: [
        {
          key: 'total',
          label: 'All Issues'
        },
        {
          key: 'active',
          label: 'Active Issues'
        },
        {
          key: 'resolved',
          label: 'Resolved Issues'
        },
        {
          key: 'ignored',
          label: 'Ignored Issues'
        }
      ],
      projectTypeOptions: [
        {
          key: 'nodejs',
          label: 'Node.js'
        },
        {
          key: 'javascript',
          label: 'Javascript'
        },
        {
          key: 'vuejs',
          label: 'VueJs'
        },
        {
          key: 'reactjs',
          label: 'ReactJs'
        },
        {
          key: 'angularjs',
          label: 'AngularJs'
        },
        {
          key: 'dotnet',
          label: 'ASP.NET'
        },
        {
          key: 'php',
          label: 'PHP'
        },
        {
          key: 'wordpress',
          label: 'Wordpress'
        },
        {
          key: 'iot',
          label: 'IOT Devices'
        },
        {
          key: 'others',
          label: 'Others'
        }
      ],

      webMessageTypes: {
        popup: { displayText: 'Popup' },
        flyout: { displayText: 'Flyout' },
        embed: { displayText: 'Embeded Block' }
      },

      RGBAToHexA(r, g, b, a) {
        r = r.toString(16);
        g = g.toString(16);
        b = b.toString(16);
        a = Math.round(a * 255).toString(16);

        if (r.length == 1) r = '0' + r;
        if (g.length == 1) g = '0' + g;
        if (b.length == 1) b = '0' + b;
        if (a.length == 1) a = '0' + a;

        let hex = '#' + r + g + b + a;
        return hex;
      },

      get24HoursList() {
        let hours = [];
        for (let i = 0; i < 24; i++) {
          let hour = i > 12 ? i - 12 : i;
          let amPm = i < 12 ? 'AM' : 'PM';
          let appendZero = hour < 10 ? '0' : '';

          hours.push({
            key: i,
            label: appendZero + hour + ':00 ' + amPm
          });
          hours.push({
            key: i + 0.5,
            label: appendZero + hour + ':30 ' + amPm
          });
        }
        return hours;
      },

      generateUUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
      },

      // Custom Fonts
      async getEmailFonts(onlyWebFonts = false) {
        if (customFonts == null) {
          customFonts = [];
          let response = await companyModule.getCompanySettingByKey('custom-fonts');
          let rawFonts = response.data ? JSON.parse(response.data) : [];
          for (let i = 0; i < rawFonts.length; i++) {
            customFonts.push({
              label: rawFonts[i].name,
              value: rawFonts[i],
              isCustomFont: true
            });
          }
          // console.log('Custom fonts are', customFonts);
        }

        let defaultWebFonts = [
          { label: 'Arial', value: 'Arial' },
          { label: 'Arial Black', value: 'Arial Black, Arial' },
          { label: 'Century Gothic', value: 'Century Gothic, AppleGothic, Arial' },
          { label: 'Courier', value: 'Courier' },
          { label: 'Courier New', value: 'Courier New' },
          { label: 'Georgia', value: 'Georgia' },
          { label: 'Geneva', value: 'Geneva, Arial' },
          { label: 'Helvetica', value: 'Helvetica, Arial' },
          { label: 'Helvetica Neue', value: '"Helvetica Neue", Arial' },
          { label: 'Lucida', value: '"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;' },
          { label: 'Lucida Grande', value: '"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;' },
          { label: 'Lucida Sans', value: '"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;' },
          { label: 'MS Serif', value: '"MS Serif", Georgia' },
          { label: 'New York', value: '"New York", Georgia' },
          { label: 'Palatino', value: 'Palatino, Georgia' },
          { label: 'Palatino Linotype', value: '"Palatino Linotype", Palatino, Georgia' },
          { label: 'Tahoma', value: 'Tahoma, sans-serif' },
          { label: 'Times New Roman', value: 'Times New Roman' },
          { label: 'Trebuchet MS', value: '"Trebuchet MS"' },
          { label: 'Verdana', value: 'Verdana' }
        ];

        if (onlyWebFonts) {
          return [
            {
              label: 'Web Fonts',
              options: defaultWebFonts
            }
          ];
        }
        return [
          {
            label: 'Custom Fonts',
            options: customFonts
          },
          {
            label: 'Web Fonts',
            options: defaultWebFonts
          }
        ];
      }
    };
  }
};
