const axios = require('axios');
const { baseUrlNew } = require('./settings.js');

exports.login = function (params) {
  return axios.post(baseUrlNew + 'auth/login', params);
};

exports.shopifyConnectLogin = function (params) {
  return axios.post(baseUrlNew + 'auth/shopify-connect-login', params);
};

exports.logout = function () {
  return axios.get(baseUrlNew + 'auth/logout');
};

exports.register = function (params) {
  return axios.post(baseUrlNew + 'auth/signup', params);
};

exports.sendPasswordResetLink = function (params) {
  return axios.post(baseUrlNew + 'auth/password/token/send', params);
};

exports.resetPassword = function (params) {
  return axios.post(baseUrlNew + 'auth/password/reset', params);
};

exports.verifyInviteToken = function (params) {
  return axios.post(baseUrlNew + 'auth/invite/verify', params);
};

exports.acceptInvitation = function (params) {
  return axios.post(baseUrlNew + 'auth/invite/accept', params);
};
