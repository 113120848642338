<template>
  <div id="mainMenu">
    <SideMenuComponent></SideMenuComponent>

    <div id="subSideMenu" v-if="selectedSection != 'Scheduled Reports'">
      <!-- SubSide Title -->
      <div class="title">
        {{ selectedSection }}
      </div>

      <!-- Dashboard -->
      <div v-if="selectedSection == 'Dashboard'">
        <DashboardCardListComponent></DashboardCardListComponent>
      </div>

      <template v-else>
        <!-- Analytics Section -->
        <div v-if="selectedSection == 'Analytics'">
          <router-link to="/analytics/events">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'EventList' }">All Events</div>
          </router-link>
          <router-link to="/analytics/chart">
            <div index="ChartList" class="menuItem" :class="{ selectedItem: selectedTab == 'ChartList' }">Event Trends</div>
          </router-link>
          <router-link to="/analytics/funnel">
            <div index="Funnel" class="menuItem" :class="{ selectedItem: selectedTab == 'Funnel' }">Funnel Analysis</div>
          </router-link>
          <router-link to="/analytics/retention">
            <div index="Cohort" class="menuItem" :class="{ selectedItem: selectedTab == 'Cohort' }">Cohort Analysis</div>
          </router-link>
          <router-link to="/analytics/revenue">
            <div index="Revenue" class="menuItem" :class="{ selectedItem: selectedTab == 'Revenue' }">Revenue Analysis</div>
          </router-link>
          <router-link to="/segment/rfm">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'RFM' }">RFM Analysis</div>
          </router-link>
          <!-- <router-link to="/installs">
              <div index="AppInstalls" class="subMenuItem">App Installs</div>
            </router-link>
            <router-link to="/uninstalls">
              <div index="AppUninstalls" class="subMenuItem">App Uninstalls</div>
            </router-link> -->
          <!-- Events -->
        </div>

        <!-- Customers Section -->
        <div v-if="selectedSection == 'Customers'">
          <!-- Overview -->
          <router-link to="/customer/overview">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'CustomerOverview' }">Overview</div>
          </router-link>
          <!-- Customers List -->
          <router-link to="/customer">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'CustomerList' }">Customer Profiles</div>
          </router-link>
          <!-- CSV Uploads -->
          <router-link to="/customer/uploads">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'CustomerUploads' }">Import Customers</div>
          </router-link>

          <div class="menuItemHeader">Segmentation</div>

          <!-- Live Segments -->
          <router-link to="/segment/query">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'SegentQueryExecution' }">Segment Queries</div>
          </router-link>

          <!-- Live Segments -->
          <router-link to="/segment/live">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'SavedSegmentList' }">Saved Segments</div>
          </router-link>

          <!-- Static Segments -->
          <router-link to="/segment/static-list">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'StaticLists' }">Static Lists</div>
          </router-link>
          <!-- Customers -->
          <router-link to="/segment/rfm">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'RFM' }">RFM Segments</div>
          </router-link>

          <div class="menuItemHeader">Other</div>
          <!-- Profile Attributes -->
          <router-link to="/customer/profile-attributes">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'CustomerAttributes' }">Customer Attributes</div>
          </router-link>
        </div>

        <!-- Campaigns Section -->
        <div v-if="selectedSection == 'Campaigns'">
          <router-link to="/campaign/overview">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'CampaignOverview' }">Overview</div>
          </router-link>

          <div class="menuItemHeader">Campaigns</div>

          <!-- Journeys -->
          <router-link to="/journey">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'JourneyList' }">Journeys</div>
          </router-link>

          <!-- Email -->
          <router-link to="/campaigns/email">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'EmailCampaignList' }">Email</div>
          </router-link>

          <!-- mobilePush -->
          <router-link to="/campaigns/mobilePush">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'MobilePushCampaignList' }">Mobile Push</div>
          </router-link>

          <!-- WebPush -->
          <router-link to="/campaigns/webPush">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'WebPushCampaignList' }">Web Push</div>
          </router-link>

          <!-- Sms -->
          <router-link to="/campaigns/sms">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'SmsCampaignList' }">SMS</div>
          </router-link>

          <!-- Whatsapp -->
          <router-link to="/campaigns/whatsApp">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'WhatsAppCampaignList' }">Whats App</div>
          </router-link>

          <template v-if="showFacebookSync()">
            <!-- Facebook -->
            <router-link to="/remarketing/facebook">
              <div class="menuItem" :class="{ selectedItem: selectedTab == 'FacebookCampaignList' }">
                Facebook
                <div class="menuBetaTag">BETA</div>
              </div>
            </router-link>

            <!-- Google -->
            <!-- <router-link to="/remarketing/google">
              <div class="menuItem" :class="{ selectedItem: selectedTab == 'GoogleCampaignList' }">
                Google
                <div class="menuBetaTag">BETA</div>
              </div>
            </router-link> -->
          </template>

          <!-- Custom Connector Channel -->
          <template v-if="$store.state.token.projectId == '1o0cysi1qkzwu9mcu'">
            <router-link to="/campaigns/custom">
              <div class="menuItem" :class="{ selectedItem: selectedTab == 'CustomChannelCampaignList' }">Custom Connector</div>
            </router-link>
          </template>

          <!-- In-App -->
          <!-- <router-link to="/campaigns/inApp">
          <div index="InAppCampaignList">
            <i class="fa fa-mobile" aria-hidden="true"></i>
            <span class="subMenu" >In App</span>
          </div>
        </router-link> -->
          <div class="menuItemHeader">On-site Displays</div>
          <router-link to="/webMessage/popup">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'WebPopup' }">Forms & Popups</div>
          </router-link>

          <!-- Coupons -->
          <div class="menuItemHeader">Other</div>
          <!-- <router-link to="/templates/create">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'Templates' }">Email Templates</div>
          </router-link> -->
          <router-link to="/coupon">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'Coupon' }">Coupons</div>
          </router-link>
          <router-link to="/assets/image">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'ImageAssets' }">Image Assets</div>
          </router-link>
        </div>

        <!-- Settings Menu -->
        <div v-if="selectedSection == 'Settings'">
          <div class="menuItemHeader">General</div>

          <!-- Brand -->
          <router-link to="/settings">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'settings-brand' }">Brand Info</div>
          </router-link>

          <!-- Users -->
          <router-link to="/settings/users">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'settings-users' }">Users</div>
          </router-link>

          <!-- Api Keys -->
          <router-link to="/settings/integration">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'settings-integration' }">Api Keys</div>
          </router-link>

          <!-- Campaign Settings -->
          <router-link to="/settings/campaign-settings">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'campaign-settings' }">Campaign Settings</div>
          </router-link>

          <!-- Channels Header -->
          <div v-if="!allChannelsDisabled" class="menuItemHeader">Channels</div>

          <!-- Email Settings -->
          <router-link v-if="channelDisableSettings && !channelDisableSettings['email']" to="/settings/channels/email">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'settings-channels-email' }">Email</div>
          </router-link>

          <!-- Sms Settings -->
          <router-link v-if="channelDisableSettings && !channelDisableSettings['sms']" to="/settings/channels/sms">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'settings-channels-sms' }">SMS</div>
          </router-link>

          <!-- Whatsapp Settings -->
          <router-link v-if="channelDisableSettings && !channelDisableSettings['whatsApp']" to="/settings/channels/whatsApp">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'settings-channels-whatsApp' }">Whats App</div>
          </router-link>

          <!-- Mobile Push Settings -->
          <router-link v-if="channelDisableSettings && !channelDisableSettings['mobilePush']" to="/settings/channels/mobilePush">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'settings-channels-mobilePush' }">Mobile Push</div>
          </router-link>

          <!-- WebPush Settings -->
          <router-link v-if="channelDisableSettings && !channelDisableSettings['webPush']" to="/settings/channels/webPush">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'settings-channels-webPush' }">Web Push</div>
          </router-link>

          <!-- Social Media Settings -->
          <router-link v-if="channelDisableSettings && !channelDisableSettings['socialMedia']" to="/settings/channels/social-media">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'settings-channels-socialmedia' }">Social Media</div>
          </router-link>

          <div class="menuItemHeader" v-if="$store.state.token.companyPlatformType != 'shopify'">Other</div>

          <!-- Coupon Settings -->
          <router-link to="/settings/coupon" v-if="$store.state.token.companyPlatformType != 'shopify'">
            <div class="menuItem" :class="{ selectedItem: selectedTab == 'settings-coupon' }">Coupon Settings</div>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" src="./subSideMenu.scss"></style>

<script>
import SideMenuComponent from './../sideMenu/sideMenu';
import DashboardCardListComponent from '@/components/dashboardCardList/dashboardCardList';
import companyService from '@/services/company';
export default {
  name: 'app',
  data() {
    return {
      selectedSection: '',
      selectedTab: '',

      // Channel Disable Settings
      allChannelsDisabled: false,
      channelDisableSettings: null,
      channelDisableSettingsTemplate: {
        email: false,
        sms: false,
        whatsApp: false,
        webPush: false,
        socialMedia: false
      }
    };
  },
  components: {
    SideMenuComponent,
    DashboardCardListComponent
  },
  computed: {},
  methods: {
    setSelectedSection() {
      this.selectedSection = this.getCurrentMenuSection();
    },

    async handleMenuVisiblity() {
      if (this.selectedSection == 'Settings') {
        this.allChannelsDisabled = false;

        // Check channels visiblity.
        let response = await companyService.getCompanySettingByKey('channel-edit-disabled-settings');
        let settings = response.data;

        if (settings) {
          settings = JSON.parse(settings);
          this.channelDisableSettings = this.overrideJson(this.channelDisableSettingsTemplate, settings);

          // If all channels disabled, remove menu header as well.
          let keys = Object.keys(this.channelDisableSettings);
          let allChannelsDisabled = true;
          for (let i = 0; i < keys.length; i++) {
            if (!this.channelDisableSettings[keys[i]]) {
              allChannelsDisabled = false;
              break;
            }
          }
          this.allChannelsDisabled = allChannelsDisabled;
        } else {
          this.channelDisableSettings = JSON.parse(JSON.stringify(this.channelDisableSettingsTemplate));
        }
      }
    },

    setSelectedTab() {
      if (location.pathname.startsWith('/customer/overview')) {
        this.selectedTab = 'CustomerOverview';
      } else if (location.pathname.startsWith('/customer/upload')) {
        this.selectedTab = 'CustomerUploads';
      } else if (location.pathname.startsWith('/customer/profile-attributes')) {
        this.selectedTab = 'CustomerAttributes';
      } else if (location.pathname.startsWith('/customer')) {
        this.selectedTab = 'CustomerList';
      } else if (location.pathname.startsWith('/segment/rfm')) {
        this.selectedTab = 'RFM';
      } else if (location.pathname.startsWith('/segment/query')) {
        this.selectedTab = 'SegentQueryExecution';
      } else if (location.pathname.startsWith('/segment/static-list')) {
        this.selectedTab = 'StaticLists';
      } else if (location.pathname.startsWith('/segment')) {
        this.selectedTab = 'SavedSegmentList';
      }

      // Campaigns Section
      else if (location.pathname.startsWith('/journey')) {
        this.selectedTab = 'JourneyList';
      } else if (location.pathname.startsWith('/campaign/overview')) {
        this.selectedTab = 'CampaignOverview';
      } else if (location.pathname.startsWith('/campaigns/email')) {
        this.selectedTab = 'EmailCampaignList';
      } else if (location.pathname.startsWith('/campaigns/sms')) {
        this.selectedTab = 'SmsCampaignList';
      } else if (location.pathname.startsWith('/campaigns/webPush')) {
        this.selectedTab = 'WebPushCampaignList';
      } else if (location.pathname == '/') {
        this.selectedTab = 'Dashboard';
      } else if (location.pathname.startsWith('/campaigns/mobilePush')) {
        this.selectedTab = 'MobilePushCampaignList';
      } else if (location.pathname.startsWith('/installs')) {
        this.selectedTab = 'AppInstalls';
      } else if (location.pathname.startsWith('/uninstalls')) {
        this.selectedTab = 'AppUninstalls';
      } else if (location.pathname.startsWith('/campaigns/whatsApp')) {
        this.selectedTab = 'WhatsAppCampaignList';
      } else if (location.pathname.startsWith('/campaigns/inApp')) {
        this.selectedTab = 'InAppCampaignList';
      } else if (location.pathname.startsWith('/campaigns/webSocialProof')) {
        this.selectedTab = 'WebSocialProof';
      } else if (location.pathname.startsWith('/webMessage')) {
        this.selectedTab = 'WebPopup';
      } else if (location.pathname.startsWith('/coupon')) {
        this.selectedTab = 'Coupon';
      } else if (location.pathname.startsWith('/remarketing/facebook')) {
        this.selectedTab = 'FacebookCampaignList';
      } else if (location.pathname.startsWith('/remarketing/google')) {
        this.selectedTab = 'GoogleCampaignList';
      } else if (location.pathname.startsWith('/assets/image')) {
        this.selectedTab = 'ImageAssets';
      }

      // Analytics SEction
      else if (location.pathname.startsWith('/session')) {
        this.selectedTab = 'SessionList';
      } else if (location.pathname.startsWith('/analytics/chart')) {
        this.selectedTab = 'ChartList';
      } else if (location.pathname.startsWith('/analytics/funnel')) {
        this.selectedTab = 'Funnel';
      } else if (location.pathname.startsWith('/analytics/revenue')) {
        this.selectedTab = 'Revenue';
      } else if (location.pathname.startsWith('/analytics/retention')) {
        this.selectedTab = 'Cohort';
      } else if (location.pathname.startsWith('/analytics/events')) {
        this.selectedTab = 'EventList';
      }

      // Settings
      else if (location.pathname.startsWith('/settings/users')) {
        this.selectedTab = 'settings-users';
      } else if (location.pathname.startsWith('/settings/integration')) {
        this.selectedTab = 'settings-integration';
      } else if (location.pathname.startsWith('/settings/campaign-settings')) {
        this.selectedTab = 'campaign-settings';
      } else if (location.pathname.startsWith('/settings/channels/email')) {
        this.selectedTab = 'settings-channels-email';
      } else if (location.pathname.startsWith('/settings/channels/sms')) {
        this.selectedTab = 'settings-channels-sms';
      } else if (location.pathname.startsWith('/settings/channels/whatsApp')) {
        this.selectedTab = 'settings-channels-whatsApp';
      } else if (location.pathname.startsWith('/settings/channels/mobilePush')) {
        this.selectedTab = 'settings-channels-mobilePush';
      } else if (location.pathname.startsWith('/settings/channels/webPush')) {
        this.selectedTab = 'settings-channels-webPush';
      } else if (location.pathname.startsWith('/settings/channels/social-media')) {
        this.selectedTab = 'settings-channels-socialmedia';
      } else if (location.pathname.startsWith('/settings/coupon')) {
        this.selectedTab = 'settings-coupon';
      } else if (location.pathname.startsWith('/settings')) {
        this.selectedTab = 'settings-brand';
      }

      // If none found, use dashboard.
      else if (location.pathname.startsWith('/scheduled-report')) {
        this.selectedTab = 'schduled-report';
      }

      // If none found, use dashboard.
      else {
        this.selectedTab = 'Dashboard';
      }
    }
  },

  //Route Change handling
  watch: {
    $route() {
      this.setSelectedTab();
      this.setSelectedSection();
      this.handleMenuVisiblity();
    }
  },

  created() {
    this.setSelectedTab();
    this.setSelectedSection();
    this.handleMenuVisiblity();
  }
};
</script>
