import Vue from 'vue';

// Configure Sentry
import * as Sentry from '@sentry/vue';
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  Sentry.init({
    Vue: Vue,
    dsn: 'https://1981713ec49345ca9902d26603ffcfe1@sentry.io/1382053'
  });
}

import './assets/tailwind.css';

// Configure logrocket
import LogRocket from 'logrocket';
if (process.env.NODE_ENV === 'production') {
  // If user has already loggedin, mark session with loggedin customer
  if (store.state.token && store.state.token.email == 'ravi@growlytics.in') {
    // Dont run for internal users.
  } else {
    // If logged in, skip ravi@growlytics.in
    LogRocket.init('di23ot/dashboard-vue');
    Vue.config.errorHandler = function (err) {
      console.error(err);
      LogRocket.captureException(err);
    };

    if (store.state.token) {
      // Add User Info As Well
      let token = store.state.token;
      let logrocketId = token.companyId + '_' + token.userId;
      LogRocket.identify(logrocketId, {
        userId: token.userId,
        name: token.firstName + ' ' + token.lastName,
        role: token.role
      });
    }
  }
}

import store from './store/store';

import router from './router';
router.beforeEach((to, from, next) => {
  //Restrict urls for unauthenticated urls
  var unauthenticatedUrls = ['/login', '/login/', '/shopify-connect', '/shopify-connect/', '/register', '/register/', '/forgotPass', '/forgotPass/', '/confirmEmail', '/confirmEmail/', '/invitation', '/invitation/'];

  if (unauthenticatedUrls.indexOf(to.path) < 0) {
    const afterLogin = to.query.afterLogin ? to.query.afterLogin : '/';

    //Perform Authentication
    if (!store.state.token) {
      store.dispatch('setHeaderVisiblity', false);
      next('/login?afterLogin=' + afterLogin);
    } else {
      // To Be Removed: Approval Part: If approval required and not approval page, redirect to approval page.
      if (!store.state.token.isAccountApproved && !to.path.startsWith('/approval')) {
        next('/approval');
        return;
      } else if (to.path.startsWith('/approval') || to.path.startsWith('/suspended')) {
        next();
        return;
      }

      // If token present, but company not present.
      // Redirect to select company page.
      if (!store.state.token.companyId && !to.fullPath.startsWith('/company/select')) {
        next('/company/select');
        return;
      }

      store.dispatch('setHeaderVisiblity', true);

      next();
    }
  } else {
    store.dispatch('setHeaderVisiblity', false);
    next();
  }
});

import App from './App.vue';

// ---------------------- Add bootstrap ---------------------- //
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

// -------- Growlytics SDK - Initialize --------------- //
let initGrowlytics = function () {
  if (!window.Growlytics) {
    setTimeout(() => {
      initGrowlytics();
    }, 500);
    return;
  }

  let growlyticsSdkSettings = {
    apiKey: '1xh60cketykl3gqwwu1xh60cketykl3gqwwv',
    enabled: process.env.NODE_ENV === 'production' ? true : false
  };
  window.Growlytics.initialize(growlyticsSdkSettings);
};
initGrowlytics();

// -------- Intercom - Initialize --------------- //
// ADd Intercom Script
window.intercomSettings = {
  app_id: 'pylyfm0s'
};
(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/pylyfm0s';
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();

// ---------------------- Add tree view for json viewing ---------------------- //
import TreeView from 'vue-json-tree-view';
Vue.use(TreeView);

// ---------------------- Add element ui ---------------------- //
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import './assets/scss/element-variables.scss';
Vue.use(ElementUI, {
  locale
});

// ---------------------- Add responsiveness utility ---------------------- //
import VueMq from 'vue-mq';
Vue.use(VueMq, {
  breakpoints: {
    mobile: 900,
    laptop: 1250,
    desktop: Infinity
  }
});

//Add Highcharts library
import HighchartsVue from 'highcharts-vue';
Vue.use(HighchartsVue);
import Highcharts from 'highcharts';
import loadTreeMap from 'highcharts/modules/treemap.js';
loadTreeMap(Highcharts);
Highcharts.setOptions({
  global: {
    useUTC: false
  }
});
Highcharts.setOptions({
  colors: ['#0080FF', '#b7e372', '#ff8f32', '#22bbb3', '#ffbb01', '#ff4c59', '#006780', '#2db87b', '#e962ae', '#44def2', '#57f0a5', '#7882bf', '#ff9882', '#914087', '#008377']
});

//Add hilight library for sourcode styling
import VueHighlightJS from 'vue-highlightjs';
Vue.use(VueHighlightJS);

// Add sweet alert library
import VueSweetAlert from 'vue-sweetalert';
Vue.use(VueSweetAlert);

//Add mixins
import GeneraLMixin from './mixins/commonMixins';
Vue.mixin(GeneraLMixin);
import AuthMixin from './mixins/authMixins';
Vue.mixin(AuthMixin);
import marketingMixin from './mixins/marketingMixin';

Vue.mixin(marketingMixin);

//Configure AXIOS for cross domain cookies.
var axios = require('axios');
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.message != 'Network Error') {
      console.log(error.response.status);
      if (401 === error.response.status) {
        location.href = '/login';
        error.handled = true;
      } else if (403 === error.response.status) {
        location.href = '/login';
      } else {
        if (process.env.NODE_ENV === 'production') {
          LogRocket.captureException(error);
        }

        if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
          Sentry.captureException(error);
        }
      }
      return Promise.reject(error.response);
    }
  }
);

// import { sync } from 'vuex-router-sync';
// const unsync = sync(store, router); // done. Returns an unsync callback fn

//Init app.vue component
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: (h) => h(App)
});
