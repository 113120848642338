import SideMenuComponent from '@/components/sideMenu/sideMenu';
import SubSideMenuComponent from '@/components/subSideMenu/subSideMenu';
import TopMenuComponent from '@/components/topMenu';
import AccountSuspensionWarning from '@/components/accountSuspensionWarning';
import authService from '@/services/auth';

export default {
  name: 'app',
  data() {
    return {
      showFullScreenLoader: false
    };
  },
  components: {
    SideMenuComponent,
    SubSideMenuComponent,
    TopMenuComponent,
    AccountSuspensionWarning
  },
  computed: {},
  methods: {
    onLogout() {
      authService.logout();
      this.onGoToPage('Login');
    }
  },

  created: function () {}
};
